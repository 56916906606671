import React from 'react';
import { IfHasAllPermissions } from 'c-auth-module/Components';
import DisplaysListPage from 'c-displays/Pages/DisplaysList';
import { DashboardTile } from 'c-admin/Components';
import { DashboardTileSize } from 'c-dashboard-module/types';
import { Translate } from 'c-translation';
import { Box, Grid } from '@mui/material';
import OwnerListPage from 'c-displays/Pages/OwnerList';
import PackListPage from 'c-displays/Pages/PackList';
import analoguesListPage from 'c-displays/Pages/AnaloguesList';
import tagsListPage from 'c-displays/Pages/TagsList';
import countryListPage from 'c-displays/Pages/CountryList';

const DisplaysAdminTiles = () => (
    <Box>
        <Grid container gap={4}>
            <>
                <IfHasAllPermissions
                    permissions={DisplaysListPage.systemSearch.permissions}
                    disableDebugWrap
                >
                    <DashboardTile
                        id="admin-displays-displays-list"
                        size={DashboardTileSize.Medium}
                        title={(<Translate path="Navigation.displaysDisplayList" />) as any}
                        titleIcon={DisplaysListPage.systemSearch.icon}
                        route={DisplaysListPage.systemSearch.route}
                        permissions={DisplaysListPage.systemSearch.permissions}
                    />
                    <DashboardTile
                        id="displays-owners-list"
                        size={DashboardTileSize.Medium}
                        title={(<Translate path="Navigation.displaysOwnerList" />) as any}
                        titleIcon={OwnerListPage.systemSearch.icon}
                        route={OwnerListPage.systemSearch.route}
                        permissions={OwnerListPage.systemSearch.permissions}
                    />
                    <DashboardTile
                        id="displays-packs-list"
                        size={DashboardTileSize.Medium}
                        title={(<Translate path="Navigation.displaysPacks" />) as any}
                        titleIcon={PackListPage.systemSearch.icon}
                        route={PackListPage.systemSearch.route}
                        permissions={PackListPage.systemSearch.permissions}
                    />
                    <DashboardTile
                        id="displays-analogues-list"
                        size={DashboardTileSize.Medium}
                        title={(<Translate path="Navigation.displaysAnalogue" />) as any}
                        titleIcon={analoguesListPage.systemSearch.icon}
                        route={analoguesListPage.systemSearch.route}
                        permissions={analoguesListPage.systemSearch.permissions}
                    />
                    <DashboardTile
                        id="displays-tag-list"
                        size={DashboardTileSize.Medium}
                        title={(<Translate path="Navigation.displaysTag" />) as any}
                        titleIcon={tagsListPage.systemSearch.icon}
                        route={tagsListPage.systemSearch.route}
                        permissions={tagsListPage.systemSearch.permissions}
                    />
                    <DashboardTile
                        id="displays-country-list"
                        size={DashboardTileSize.Medium}
                        title={(<Translate path="Navigation.displaysCountry" />) as any}
                        titleIcon={countryListPage.systemSearch.icon}
                        route={countryListPage.systemSearch.route}
                        permissions={countryListPage.systemSearch.permissions}
                    />
                </IfHasAllPermissions>
            </>
        </Grid>
    </Box>
);

export default DisplaysAdminTiles;
