import { createSelector } from '@reduxjs/toolkit';

export const passwordRequestResetEmailState = (state: any) =>
    state.passwordReset.requestResetEmailState;

export const passwordResetEmailRequestState = createSelector(
    (state: any) => state.passwordReset.requestResetEmailState,
    state => state.state,
);
export const passwordResetEmailRequestError = createSelector(
    (state: any) => state.passwordReset.requestResetEmailState,
    state => state.error,
);

export const passwordRequestResetPasswordState = (state: any) =>
    state.passwordReset.requestResetPasswordState;

export const passwordResetPasswordRequestState = createSelector(
    (state: any) => state.passwordReset.requestResetPasswordState,
    state => state.state,
);
export const passwordResetPasswordRequestError = createSelector(
    (state: any) => state.passwordReset.requestResetPasswordState,
    state => state.error,
);
export const passwordResetPasswordRequestValidation = createSelector(
    (state: any) => state.passwordReset.requestResetPasswordState,
    state => state.validation ?? {},
);
