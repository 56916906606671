import React from 'react';
import { Grid } from '@mui/material';
import CampaignLineItemPicker from 'c-main/Components/Campaign/CampaignLineItems/CampaignLineItemPicker';

const ChooseDisplaysStep = () => {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <CampaignLineItemPicker />
            </Grid>
        </Grid>
    );
};

export default ChooseDisplaysStep;
