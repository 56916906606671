import {
    displays_MarketActions,
    displays_MarketAdapter,
    displays_MarketThunks as defaultThunks,
    displays_MarketSelectors as defaultSelectors,
    displays_MarketReducer,
} from './Displays_Market-slice';
import * as thunks from './Displays_Market-thunks';
import * as selectors from './Displays_Market-selectors';

const displays_MarketThunks = { ...defaultThunks, ...thunks };
const displays_MarketSelectors = { ...defaultSelectors, ...selectors };

export { default as Displays_MarketSlice } from './Displays_Market-slice';
export {
    displays_MarketSelectors,
    displays_MarketActions,
    displays_MarketAdapter,
    displays_MarketThunks,
    displays_MarketReducer,
};
