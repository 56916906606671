import React from 'react';
import { Box } from '@mui/material';
import { useComponentOverrides } from 'c-wrapper/Components/CommonWrapper';

const BrandLogo = () => {
    const { BigBrandLogo } = useComponentOverrides();
    return (
        <Box color="primary.main" height={150}>
            {BigBrandLogo}
        </Box>
    );
};

export default BrandLogo;
