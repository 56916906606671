import { atom } from 'jotai';

export const atom_PacksListDialogState = atom<{ id: number | null; status: number | null }>({
    status: null,
    id: null,
});
export const atom_OwnersDialogState = atom<{ id: number | null; status: number | null }>({
    status: null,
    id: null,
});

export const atom_OwnersPackDialogState = atom<{ id: number }>({
    id: null,
});
export const atom_TagsDialogState = atom<{ id: number | null; status: number | null }>({
    status: null,
    id: null,
});
export const atom_DateRange = atom<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
});
