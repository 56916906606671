/**
 * From Ben 'The Star' Poulson
 *
 * http://www.mattgroeber.com/utilities/random-color-generator/
 *
 var colors = [];
 $('.swatch').each((index, elem) => {
    colors.push($(elem).css('background-color'));
 });
 console.log(JSON.stringify(colors));
 */

export const ChartColors = [
    'rgb(80, 71, 148)',
    'rgb(183, 206, 126)',
    'rgb(40, 67, 66)',
    'rgb(175, 40, 82)',
    'rgb(166, 135, 119)',
    'rgb(40, 53, 98)',
    'rgb(104, 78, 24)',
    'rgb(60, 195, 143)',
    'rgb(147, 72, 117)',
    'rgb(132, 200, 156)',
    'rgb(94, 68, 106)',
    'rgb(97, 58, 100)',
    'rgb(115, 105, 226)',
    'rgb(17, 84, 95)',
    'rgb(77, 128, 85)',
    'rgb(133, 213, 173)',
    'rgb(206, 100, 125)',
    'rgb(172, 114, 129)',
    'rgb(131, 208, 37)',
    'rgb(29, 165, 144)',
    'rgb(48, 85, 64)',
    'rgb(117, 53, 182)',
    'rgb(226, 126, 189)',
    'rgb(146, 104, 161)',
    'rgb(83, 74, 125)',
    'rgb(56, 84, 97)',
    'rgb(107, 100, 166)',
    'rgb(161, 38, 38)',
    'rgb(40, 53, 98)',
    'rgb(187, 158, 104)',
    'rgb(93, 60, 75)',
    'rgb(85, 69, 42)',
    'rgb(82, 172, 53)',
    'rgb(84, 19, 98)',
    'rgb(172, 93, 113)',
    'rgb(54, 206, 143)',
    'rgb(141, 95, 149)',
    'rgb(112, 210, 210)',
    'rgb(128, 191, 64)',
    'rgb(196, 115, 188)',
    'rgb(178, 92, 151)',
    'rgb(89, 117, 51)',
    'rgb(135, 141, 42)',
    'rgb(209, 137, 166)',
    'rgb(120, 97, 204)',
    'rgb(139, 187, 168)',
    'rgb(124, 34, 72)',
    'rgb(169, 122, 55)',
    'rgb(182, 124, 124)',
    'rgb(87, 103, 55)',
    'rgb(106, 74, 37)',
    'rgb(191, 153, 125)',
    'rgb(75, 94, 54)',
    'rgb(106, 132, 26)',
    'rgb(48, 84, 59)',
    'rgb(68, 121, 151)',
    'rgb(206, 128, 59)',
    'rgb(194, 76, 104)',
    'rgb(59, 151, 71)',
    'rgb(83, 63, 100)',
    'rgb(47, 147, 77)',
    'rgb(34, 119, 52)',
    'rgb(178, 205, 60)',
    'rgb(49, 76, 104)',
    'rgb(142, 203, 124)',
    'rgb(51, 82, 87)',
    'rgb(158, 200, 116)',
    'rgb(203, 154, 178)',
    'rgb(90, 209, 82)',
    'rgb(138, 206, 44)',
    'rgb(65, 134, 124)',
    'rgb(185, 106, 136)',
    'rgb(37, 55, 91)',
    'rgb(88, 105, 137)',
    'rgb(161, 162, 113)',
    'rgb(187, 180, 150)',
    'rgb(146, 28, 40)',
    'rgb(72, 36, 158)',
    'rgb(55, 44, 201)',
    'rgb(23, 83, 19)',
    'rgb(146, 136, 195)',
    'rgb(212, 120, 64)',
    'rgb(119, 175, 207)',
    'rgb(169, 74, 217)',
    'rgb(83, 85, 202)',
    'rgb(57, 51, 113)',
    'rgb(190, 100, 45)',
    'rgb(83, 84, 18)',
    'rgb(107, 145, 80)',
    'rgb(72, 132, 126)',
    'rgb(138, 210, 137)',
    'rgb(156, 79, 106)',
    'rgb(75, 175, 95)',
    'rgb(210, 164, 132)',
    'rgb(142, 117, 51)',
    'rgb(110, 198, 207)',
    'rgb(27, 47, 85)',
    'rgb(90, 206, 107)',
    'rgb(45, 200, 130)',
    'rgb(85, 138, 66)',
    'rgb(149, 184, 46)',
    'rgb(57, 43, 100)',
    'rgb(43, 115, 68)',
    'rgb(49, 104, 88)',
];
