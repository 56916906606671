import React from 'react';
import { Box } from '@mui/material';
import BrandLogo from '../BrandLogo';

const Loading = () => (
    <Box
        color="secondary.contrastText"
        bgcolor="background.default"
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        display="flex"
        height="100vh"
        flexDirection="column"
        fontSize="12rem"
    >
        <BrandLogo />
    </Box>
);

export default Loading;
