import React from 'react';
import DesktopMobileSwitcher from 'c-components/DesktopMobileSwitcher';
import { Props } from './Props';
import DesktopUserProfilePopOver from './DesktopUserProfilePopOver';
import MobileUserProfilePopOver from './MobileUserProfilePopOver';

const UserProfilePopOver = (props: Props) => (
    <>
        {props.userId && (
            <DesktopMobileSwitcher
                desktopContent={<DesktopUserProfilePopOver {...props} />}
                mobileContent={<MobileUserProfilePopOver {...props} />}
            />
        )}
    </>
);

export default UserProfilePopOver;
