import React from 'react';
import { BreadCrumbPart } from 'c-wrapper/Types';
import BreadcrumbList from './BreadcrumbList';

type Props = {
    breadcrumbs?: BreadCrumbPart[];
};

const emptyCrumbs = [];

const Breadcrumbs: React.FC<Props> = ({ breadcrumbs = emptyCrumbs }) => {
    return <BreadcrumbList breadcrumbs={breadcrumbs} />;
};

export default Breadcrumbs;
